.container {
height: 90vh;
background: linear-gradient(to top right ,rgba(238,238,238,1) 45%, #EFD449 10%
);
max-width: 100%;
}

.heading {
  color: #252626;
  opacity: 1;
  font-size: 40px;
}

.para_div{
  width: 90%;
  
}


.row{
  display: flex;
  justify-content: center;
}

.card_text{
  font-size: 14px;
  color: #000000
}


.card_img {
 
  width: 50%;
}

.div123{
  display: flex;
  justify-content: center;

}


.background {
  background-color:#EEEEEE;
  box-shadow: 1px 1px 8px 0px;
}

.card_title{
  color: #000000
}

@media (max-width: 576px){
  .container{
    min-height: 120vh;
   
  }

  .heading{
    font-size: 20px;
    padding-top: 80px;
    padding-bottom: 0 !important;
  }
  .para_text{
    font-size: 14px !important;
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 0 !important;

    padding-top: 10px !important;
    /* margin-right: 0 !important; */
    /* padding-left: 0 !important; */
    padding-right: 0 !important;
  }
  .col1{
    padding-bottom: 30px;

  }
  .row{
    padding-top: 0 !important;
    padding-bottom: 10px !important;
  }
 
}

@media (max-width: 376px){
  .container{
    height: 150vh;
  }
}
@media only screen and (max-width: 820px) and (min-width:767px){
  .container{
    height: 80vh;
  

  }

  
  .heading{
    font-size: 40px;
  }
  .col1{
    display: flex;
    justify-content: center;
  
  }
}