@media (max-width: 625px) {
  .nav1 {
    display: flex;
    align-items: flex-start;
  }
}
.small {
  padding-top: 50px;
}

@media (max-width: 568px) {
  .link2 {
    display: none;
  }
}



.navbar{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  max-width: 100%;
}


@supports (backdrop-filter: blur()) {
  .navbar {
    background-color: transparent;
    padding: 5;
    transition: all 0.15s;
    z-index: 11;
    backdrop-filter: blur(15px);
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    );
  }
}

@supports not (backdrop-filter: blur()) {
.navbar {
  background-color: rgba(248, 245, 239);
  padding: 50;
  transition: all 0.15s;
  z-index: 11;
  height: 75px;
}
}
.links{
  gap: 10;
}