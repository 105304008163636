.container {
  background-color: #FFFFFF;
  margin-bottom: -20px;
}

/* li {
  list-style: none;
} */
a {
  /* pointer-events: none; */
  text-decoration: none;
  color: black;
}

/* @media (max-width: 768px) {
  .col1 {
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .col2 {
    margin-left: 25px;
  }
  .col3 {
    margin-left: 25px;
  }
} */
/* .hrline {
  color: black;

} */

h2 {
  display: inline;
}

.img {
  width: 60%;
}

/* @media (min-width: 768px) {
  .col2 {
    padding-left: 30px;
  }
  .col3 {
    padding-left: 30px;
  }
  .col1 {
    padding-left: 30px;
  }
}

.heading_about {
  display: inline;
} */



/* .row_main{
  display: flex;
  justify-content: center;
} */

.main_cont{
  display: flex;
  justify-content: center;
}



.row_second {
  display: flex;
  justify-content: space-around;
}


.footer_list{
  list-style: none;
  font-weight: bold;
}

@media (max-width: 576px){
  .img {
    width: 15%;
    padding-top: 20px;
    padding-bottom: 0px !important;
    padding-right: 15px;
  }
  .main_cont{
    display: flex;
    justify-content: center;
  }
  .col1{
    display: flex;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0px !important;
  }
  .col2{
    display: flex;
    justify-content: center;
    padding-right: 30px;
    text-align: center;
  }
  .col3{
    display: flex;
    justify-content: center;
    padding-right: 40px;
    padding-top: 5px !important;
    text-align: center;
  }
  .col4{
    display: flex;
    justify-content: center;
    padding-right: 40px;
    padding-bottom: 15px !important;
   
  }
  .col5{
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    

  }

  .row_second{
    margin-bottom: 10px !important;
  }
  .hrline{
    margin-top: 0 !important;
  }
 
 }
 @media only screen and (max-width: 820px) and (min-width:767px){
      .img{
        width: 40%;
        padding-top: 15px;
      }
      .col4{
        display: flex;
        justify-content: center;
        padding-right: 40px;
        padding-bottom: 15px !important;
       
      }
      .col5{
        display: flex;
        justify-content: center;
        margin-bottom: 0 !important;
        
    
      }
}