.container {
  height: 87vh;
  max-width: 100%;

 
 background-color: #EFD449 ;
}

.img{
  margin-top: -50px!important;
  position: relative;
}
  

.heading {
  color: #252626;
  font-size: 40px;
}

@media (max-width: 576px){
  .heading{
    font-size: 20px;
  }

  .para_text{
    font-size: 14px !important;
  }

  .container{
    height: 60vh;
  }
  .col1{
    padding-top: 0!important;
  }
}
 
@media (max-width: 376px){
  

  .container{
    height: 60vh;
  }
}

@media only screen and (max-width: 820px) and (min-width:767px){
  .container{
    height: 60vh;
  }
}
  
