/* @import url('http://fonts.cdnfonts.com/css/arial-rounded-mt-bold'); */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&display=swap');
@import url('http://fonts.cdnfonts.com/css/arial-rounded-mt-bold');

 
                
.accordion-button:first-of-type {
  background-color: #ffb901 !important;
  color: #232323 !important;
  font-weight: bolder;
}
.accordion-button:not(.collapsed) {
  color: #232323 !important;
  font-weight: bolder;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
body,html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;                                                                  font-family: 'Arial Rounded MT Bold', sans-serif;

}

a {
  color: unset;
  text-decoration: none;
}


/* body{
  width: 100%;
} */



