.container {
  height: 90vh;
  max-width: 100%;

  /* background: linear-gradient( to top right ,rgba(238,238,238,1) 50%, rgba(255, 185, 1, 1) 70%
  ); */
  background: rgb(238,238,238);
background: linear-gradient(90deg, rgba(238,238,238,1) 90%, #EFD449 81%);
  }
  
  .heading {
    color: #252626;
    opacity: 1;
    font-size: 40px;
  }
  
  .para_div{
    width: 90%;
    
  }
  
  
  .row{
    display: flex;
    justify-content: center;
  }
  
  .card_text{
    font-size: 14px;
    color: #000000
   
  }
  
  
  .card_img {
   position:absolute;
  } 
  

  
  
  .card {
    background-color:#EEEEEE;
    box-shadow: 1px 1px 8px 0px;
  }
  
  .card_title{
    color: #929497;
  }



  .div123{
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

 

.list {
  list-style: none;
  padding-bottom: 10px;
}
 

.img123{
 margin-top: -80px;
 margin-bottom: -100px;
 width: 80%;
}

.divq{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 445px){
  .container{
    height: 170vh;
  }
  .div123{
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 0;
  }
  .list{
    font-size: 14px !important;
    padding-top: 5px !important;
  }
  .heading{
    font-size: 20px;
    padding-bottom: 20px;
    padding-top: 30px !important;
    padding-bottom: 0 !important;
  }
  .div1234{
   width: 100%;
  align-items: flex-end;
  margin-left: 0 !important;
  margin-bottom: 0;
  }
    .col1{
      padding-bottom: 35px !important;
    }
}
@media (max-width: 376px) {
  .container{
      height: 200vh;
  }
  
}

@media only screen and (max-width: 820px) and (min-width:767px){
  .container{
    height: 70vh;
  

  }
}