.hrline {
  color: #ffb901;
}

.input2 {
  width: 390px;
  height: 40px;
}

.input3 {
  width: 500px;
  height: 130px;
}

.accordion_btn {
  background-color: rgb(225, 65, 65) !important;
}

.item1 {
  background-color: transparent;
  opacity: 0.7;
  color: #232323;
  font-weight: bolder;
}
