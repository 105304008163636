.container {
  background-color: #232323;
  height: 80vh;
  /* background-size: cover;
  background-repeat: no-repeat; */
  /* display: flex;
  align-items: center;
  filter: blur(1px); */
}
.cont {
  position: relative;
}
.input1 {
  width: 390px;
  height: 40px;
}
.input2 {
  width: 390px;
  height: 40px;
}

.input3 {
  width: 800px;
  height: 130px;
}

small {
  font-size: 15px;
  color: #ffb901;
}

h1 {
  color: #ffb901;
  font-size: 35px;
}

@media (max-width: 768px) {
  .input1 {
    width: 200px;
  }
  .input2 {
    width: 200px;
  }
  .input3 {
    width: 420px;
  }
  .container {
    height: 100vh;
    margin-top: 50px;
  }
}
input[type="text"] {
  background: transparent;
  border: none;
}

input[type="textarea"] {
  background: transparent;
  border: none;
}

@media (min-width: 768px) {
  .input1 {
    width: 300px;
  }
  .input2 {
    width: 300px;
  }
  .input3 {
    width: 630px;
  }
  /* .container {
    height: 4vh;
  } */
}
@media (max-width: 568px) {
  .input1 {
    width: 170px;
  }
  .input2 {
    width: 170px;
  }
  .input3 {
    width: 355 px;
  }
  .container {
    height: 100vh;
    margin-top: 100px;
  }
}

/* @media (min-witdh: 768px) and (max-width: 852px) {
  .input1 {
    width: 100px;
  }
} */
