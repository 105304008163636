.col123 {
  background-color: #232323;
  height: 60vh;
}

.col1234 {
  background-color: #232323;
}

.col1 {
  height: 50px;
  width: 10px;
}

.para {
  text-align: center;
}
.proba1 {
  width: 300px;
}

.proba2 {
  width: 120px;
}

@media (max-width: 768px) {
  .col123 {
    height: 70vh;
  }
  .para {
    font-size: 13px;
  }
  .image {
    width: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .para {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .col123 {
    height: 80vh;
  }
}
