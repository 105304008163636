.row2 {
  display: flex;
  justify-content: space-around;
}

.slick-slide img {
  height: 300px;
}
.wrapper {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-around !important;
  height: 30vh;
}

.wrapper img {
  background-color: blue;
  height: 100 !important;
  width: 100 !important;
}

.img {
  width: 5%;
}
/* 
.description {
  display: flex;
  justify-content: center;
} */
