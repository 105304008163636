.container {
  height: 100vh;
  max-width: 100%;
 
    background: linear-gradient(to bottom right,rgba(238,238,238,1) 45.39%, #EFD449 19%
    );
  display: flex;
  justify-content: center;

    
  }

  /* .row123 {
    display: flex;
    justify-content: center;
  } */


  .heading {
    color: #000000;
    direction: rtl;
    font-size: 70px;

  }


.logo1 {
    width: 60%; 
    }
.img123{
  padding-bottom: 15px;
}

.para {
  font-size: 24px;
  color: #fff;
}



.together{
  padding-left: 100px;
}
@media (max-width: 576px){
  .container{
    height: 80vh;
    display: flex;
    justify-content: center;
    /* background-image: url("../../assets/images/Rething-logo2.png"); */
   
  }
  .row123{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .heading{
    font-size: 30px;
  }
  .col1{
    padding-top: 0!important;
    
  }
  .div{
    display: flex;
    justify-content: center;
  
  }
  .col2{
    display: flex;
    justify-content: center;
    padding-right: 0 !important;
    padding-top: 30px;
  }

 
}


@media only screen and (max-width: 820px) and (min-width:767px){
  .container{
    height: 75vh;
    display: flex;
    justify-content: center;

  }

   .div{
    display: flex;
    justify-content: center;
  
  }
  .heading{
    font-size: 70px;
  }

}

/* @media (min-width: 577px) and (max-width: 830px){
  .container{
    height: 50vh;
   
  }
  .col2{
      padding-top: 50px;
  }
  .img123{
    width: 50% !important;
  }
  .row123{
    display: flex;
    justify-content: center !important
  }
  .heading{
    font-size: 30px;
  }
 
  
} */
